.App {
    text-align: center;
}

html {
    background-color: #282c34;
}

.App-icon {
    height: 40vmin;
    margin-bottom: 20px;
}

.App-logo {
    height: 15vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    .App-icon {
        animation: App-icon-heartbeat infinite 10s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #3082CA;
}

.App-header .sm,
.App-header p a {
    font-size: 1.1rem;
    font-weight: 300;
}

h1, h2, h3 {
    color: #dedede;
}

.App-hero {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: #3082CA;
}

.App-link:hover,
.App-link:focus,
.App-link:visited,
.App-link {
    color: #3082CA;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes App-icon-heartbeat {
    0% {
        transform: scale(1);
    }
    15% {
        transform: scale(1.05) translateY(-.5dvmin);
    }
    50% {
        transform: scale(1);
    }
    65% {
        transform: scale(1.05) translateY(-.5dvmin);
    }
    100% {
        transform: scale(1);
    }
}

.App-chart-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    min-height: 400px;
    height: 600px;
    max-height: 100%;
}

.App-chart {
    /*padding: 20px;*/
}

.App-chart-left {
    float: left;
    width: 85%;
    height: 600px;
    max-height: 100%;
}

.App-chart-right {
    float: right;
    width: 15%;
    height: 600px;
    max-height: 100%;
}

/* Float to right */
.App-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    padding: 0 20px;
    text-align: left;
    margin: 0 0 80px 0;
}

.App-controls .react-select {
    margin-right: 20px;
    min-width: 240px;
}

.App-controls label {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 300;
    margin: 8px 15px 0 0;
}

.App-analytics {
    color: white;
}

.App hr {
    border: 0;
    border-top: 1px solid #33333b;
    margin: 0 10px;
    padding: 20px 0 0 0;
}

.hidden {
    display: none !important;
}

.App-controls .filter-by-value {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    margin-right: 15px;
}
.App-controls .filter-by-value input {
    color: inherit;
    background: 0 center;
    opacity: 1;
    padding: 0 8px;
    width: 100%;
    grid-area: 1 / 2;
    font: inherit;
    min-width: 2px;
    border: 0;
    margin: 0;
    outline: 0;
}

/** Float to top left with a caret */
.App-link.home {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    color: #3082CA;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 700;
}
.App-link.home::before {
    content: '←';
    margin-right: 5px;
}